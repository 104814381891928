<!-- =========================================================================================
  File Name: ProductEdit.vue
  Description: Product Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="page-product-edit">

    <vs-alert :active.sync="product_not_found" color="danger" title="Product Not Found">
      <span>Product record with id: {{ $route.params.productId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link class="text-inherit underline" to="/a/products">All Products</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="product_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-settings" icon-pack="feather" label="General">
            <div class="tab-text">
              <product-edit-tab-general :data="product_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-dollar-sign" icon-pack="feather" label="Pricing" v-if="product_data.type === 'service'">
            <div class="tab-text">
              <product-edit-tab-pricing :data="product_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-shopping-bag" icon-pack="feather" label="Children" v-if="product_data.type === 'bundle'">
            <div class="tab-text">
              <product-edit-tab-children :data="product_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import ProductEditTabGeneral from './ProductEditTabGeneral.vue';
import ProductEditTabChildren from './ProductEditTabChildren.vue';
import ProductEditTabPricing from './ProductEditTabPricing.vue';

// Store Module

export default {
  components: {
    ProductEditTabGeneral,
    ProductEditTabChildren,
    ProductEditTabPricing,
  },
  data() {
    return {
      product_data: null,
      product_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchProductData(this.$route.params.productId);
    },
  },
  methods: {
    fetchProductData(productId) {

      this.$http.get(`billing/products/${productId}`)
        .then(response => {

          if (response.data.data) {
            this.product_data = response.data.data;
            const pictureId = this.product_data.image || 'default';
            this.product_data.photoURL = `https://portal-cdn.rapid.education/product-pictures/${pictureId}.jpeg`;
          }

        })
        .catch(error => {

          if (error.response.status === 404) {
            this.product_not_found = true;
            return;
          }
        });
    },
  },
  created() {
    this.fetchProductData(this.$route.params.productId);
  },
};

</script>
