<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar class="add-new-data-sidebar items-no-padding" click-not-close color="primary" default-index="1"
              parent="body"
              position-right spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 1 ? 'ADD NEW' : 'UPDATE' }} PRICE</h4>
      <feather-icon @click.stop="isSidebarActiveLocal = false" class="cursor-pointer" icon="XIcon"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" :key="$vs.rtl" :settings="settings" class="scroll-area--data-list-add-new">

      <div class="p-6">

        <!-- AMOUNT -->
        <vs-input @change="setAmountRounded" class="mt-5 w-full" icon="icon-dollar-sign" icon-pack="feather" label="Amount"
                  name="item-amount" step=".01" type="number" v-model="dataAmount" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('item-amount')">{{ errors.first('item-amount') }}</span>

        <div class="pt-5" v-if="Object.entries(this.data).length === 1">
          <label class="text-sm">Pricing Interval</label>

          <v-select
            :options="intervals"
            color="danger"
            label="priceDisplay"
            v-model="dataInterval"/>

          <!-- INTERVAL COUNT -->
          <vs-input class="mt-5 w-full" label="Price Interval Count" name="item-interval-count" type="number"
                    v-if="dataInterval !== 'one-time'" v-model="dataIntervalCount" v-validate="'required'"/>
          <span class="text-danger text-sm" v-show="errors.has('item-interval-count')">{{
              errors.first('item-interval-count')
            }}</span>

        </div>
        <div class="pt-5" v-else>
          <label>Hidden</label>
          <vs-switch color="danger" v-model="dataHidden"/>

          <label>Archived</label>
          <vs-switch color="danger" v-model="dataArchived"/>
        </div>


      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button :disabled="!isFormValid" @click="submitData" class="mr-6">Submit</vs-button>
      <vs-button @click="isSidebarActiveLocal = false" color="danger" type="border">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
  },
  data() {
    return {

      dataProductId: '',

      dataId: null,
      dataAmount: '',
      dataHidden: false,
      dataArchived: false,
      dataInterval: 'year',
      dataIntervalCount: 1,

      intervals: [
        'year',
        'month',
        'week',
        'day',
      ],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 1) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {id, product_id, amount, interval, interval_count, archived, hidden} = JSON.parse(
          JSON.stringify(this.data));
        this.dataId = id;
        this.dataProductId = product_id;
        this.dataAmount = amount.toFixed(2);
        this.dataArchived = archived;
        this.dataHidden = hidden;
        this.dataInterval = interval;
        this.dataIntervalCount = interval_count;
        this.initValues();
      }

      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.dataAmount > 0 && this.dataInterval !== undefined;
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    setAmountRounded() {
      this.dataAmount = parseFloat(this.dataAmount).toFixed(2);
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataProductId = this.data.product_id;
      this.dataAmount = 0;
      this.dataArchived = false;
      this.dataHidden = false;
      this.dataInterval = 'year';
      this.dataIntervalCount = 1;
    },
    submitData() {
      this.$validator.validateAll().then(result => {

        this.$vs.loading();

        if (result) {
          const obj = {
            id: this.dataId,
            amount: parseFloat(this.dataAmount),
            recurring: true,
            interval: this.dataInterval,
            interval_count: parseInt(this.dataIntervalCount),
            archived: this.dataArchived,
            hidden: this.dataHidden,
          };

          if (obj.interval === 'one-time') {
            obj.interval = null;
            obj.recurring = false;
          }

          if (this.dataId !== null && this.dataId >= 0) {

            this.$http
              .post(`billing/products/${this.data.product_id}/prices/${this.dataId}`, obj)
              .then((response) => {
                this.$vs.loading.close();

                this.$emit('closeSidebar');

                this.$parent.loadChildrenAttr();

                return this.$vs.notify({
                  color: 'success',
                  title: 'Modified Price',
                  text: 'Your price has been modified',
                });
              })
              .catch(exception => {
                this.$vs.loading.close();

                let details = 'An unknown error occurred while modifying the PRICE. This has been reported.';

                if (exception.response) {

                  const response = exception.response;

                  if (response.status === 400) {
                    details = response.data.error.description;
                  }

                }

                return this.$vs.notify({
                  title: 'Failed to modify PRICE',
                  text: details,
                  color: 'danger',
                  position: 'top-right',
                });
              });

          } else {
            this.$http
              .post(`billing/products/${this.data.product_id}/prices`, obj)
              .then((response) => {
                this.$vs.loading.close();
                this.$emit('closeSidebar');

                if (response.status === 200) {
                  this.$parent.loadChildrenAttr();
                  return this.$vs.notify({
                    color: 'success',
                    title: 'Created Price',
                    text: 'Your price has been created',
                  });
                }

                return this.$vs.notify({
                  title: 'Failed to create price',
                  text: response.data.error.description,
                  color: 'danger',
                  position: 'top-right',
                });
              })
              .catch(exception => {
                this.$vs.loading.close();

                let details = 'An unknown error occurred while creating the price. This has been reported.';

                if (exception.response) {

                  const response = exception.response;

                  if (response.status === 400) {
                    details = response.data.error.description;
                  }

                }

                return this.$vs.notify({
                  title: 'Failed to create price',
                  text: details,
                  color: 'danger',
                  position: 'top-right',
                });
              });
          }

        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
