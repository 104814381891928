<!-- =========================================================================================
  File Name: ProductEditTabInformation.vue
  Description: Product Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="product-edit-tab-info">
    <product-edit-sidebar :data="sidebarData" :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar"/>


    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <vs-row vs-justify="space-between" vs-type="flex">
          <vs-col vs-align="flex-start" vs-justify="flex-start" vs-type="flex">
            <span style="min-width:175px;" class="leading-none font-medium">Add Price to Product</span>

            <vs-col vs-align="flex-end" vs-justify="flex-end" vs-type="flex">

              <vs-button @click="addNewData" class="ml-5" color="danger" type="gradient">
                Create an Entry
              </vs-button>
            </vs-col>
          </vs-col>


        </vs-row>

        <vs-row>


          <div class="flex items-end pt-10">
            <span class="leading-none font-medium">Existing Children</span>
          </div>

          <vs-table :data="pricing" class="flex w-full pt-3">

            <template slot="thead">
              <vs-th>ID</vs-th>
              <vs-th>Price</vs-th>
              <vs-th>Hidden</vs-th>
              <vs-th>Archived</vs-th>
              <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in pricing">

                <vs-td :data="tr.id">
                  {{ tr.id }}
                </vs-td>

                <vs-td>{{ tr.display }}</vs-td>
                <vs-td>{{ tr.hidden ? 'Yes' : 'No' }}</vs-td>
                <vs-td>{{ tr.archived ? 'Yes' : 'No' }}</vs-td>
                <vs-td>
                  <vs-button @click="editData(tr)"
                             color="danger"
                             icon="icon-edit-2"
                             icon-pack="feather"
                             type="gradient"
                  />
                </vs-td>

              </vs-tr>
            </template>

          </vs-table>

        </vs-row>
      </div>

    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';

import 'vue-simple-suggest/dist/styles.css';

import ProductEditSidebar from '@/views/apps/product/product-edit/ProductEditSidebar';

export default {
  components: {
    vSelect,
    flatPickr,
    'product-edit-sidebar': ProductEditSidebar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.loadChildrenAttr();
  },
  data() {
    return {

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      loading: false,
      model: null,
      pricing: [],
      price: null,

      children: [],

      data_local: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },

  },
  methods: {
    addNewData() {
      this.sidebarData = {
        product_id: this.data_local.id,
      };
      this.toggleDataSidebar(true);
    },
    editData(data) {
      this.sidebarData = {...data, ...{product_id: this.data_local.id}};
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },

    loadChildrenAttr() {
      this.$vs.loading();
      let i = setInterval(() => {
        if (this.children.length === this.data_local.children_ids.length) {
          clearInterval(i);
          this.$vs.loading.close();
        }
      }, 100);

      this.$http.get(`billing/products/${this.data_local.id}/prices`)
        .then(response => {

          if (response.status === 200) {

            const data = response.data.data;

            this.pricing = data.map(element => {

              element.display = 'One-Time';

              if (element.recurring) {

                const ic = element.interval_count;
                const plural = ic !== 1;

                element.display = `$${element.amount.toFixed(2)} w/ access for ${ic} ${element.interval}${plural ? 's' : ''}`;

              }

              return element;
            });

          }

        });

    },

    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
</style>

