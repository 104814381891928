<!-- =========================================================================================
  File Name: ProductEditTabInformation.vue
  Description: Product Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="product-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="ProductIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Product Information</span>
    </div>

    <div class="vx-row pb-10">
      <div class="vx-col md:w-2/5 w-full">
        <vs-input class="w-full mt-4" label="Product Name" name="product_name" v-model="data_local.name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('product_name')">{{ errors.first('product_name') }}</span>

      </div>
      <div class="vx-col md:w-1/5 w-full">
        <vs-input class="w-full mt-4" label="Product Position" name="product_position" v-model="data_local.position"
                  v-validate="'required'" type="number" step="1"/>
        <span class="text-danger text-sm" v-show="errors.has('product_position')">{{ errors.first('product_position') }}</span>

      </div>

      <div class="vx-col md:w-1/5 w-full mt-4">

        <label class="vs-input--label" for="product_status">Service</label>
        <v-select :options="services" class="w-full" name="product_service" v-model="data_local.service"/>

      </div>
      <div class="vx-col md:w-1/5 w-full mt-4">

        <label class="vs-input--label" for="product_status">Product Status</label>
        <v-select :options="['disabled','active', 'hidden']" class="w-full" label="test" name="product_status"
                  v-model="data_local.status"/>

      </div>

      <div class="vx-col w-full mt-4">

        <vs-textarea height="200px" class="w-full" label="Product Description" name="product_description" v-model="data_local.description"/>

      </div>

      <div class="vx-col md:w-2/5 w-full mt-2">
        <vs-input class="w-full " label="Partner ID" v-model="data_local.partner_id"
                  v-validate="'required'"/>
      </div>
    </div>



    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="ProductIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Product Image</span>
    </div>

    <!-- Avatar Row -->
    <div class="vx-row pt-5">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="data_local.photoURL" alt="Product Image" class="avatar mr-8 rounded"/>
          <div>
            <input @change="selectFile" class="d-none pb-2 mt-3" id="file" ref="file" type="file"><br/>
            <vs-button @click="submitPicture" class="mr-4 sm:mb-0 mb-2">Upload photo</vs-button>
            <p class="text-sm mt-2">Allowed JPG or PNG. Max size of 15MB</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" @click="initiateProductUpdate" class="ml-auto mt-2">Save Changes
          </vs-button>
          <vs-button @click="resetData" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  mounted() {
    this.loadServices();
  },
  methods: {
    loadServices() {

      this.$vs.loading();

      this.$http.get(`services?internal=true`)
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 200) {

            this.services = response.data.data.map(service => {
              if (this.data_local.service_id === service.id) {
                this.data_local.service = {
                  label: service.name,
                  code: service.id,
                };
              }

              return {
                label: service.name,
                code: service.id,
              };
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();

          let details = 'An unknown error occurred while fetching the service list. This has been reported.';

          if (exception.response) {

            const response = exception.response;

            details = response.data.error.description;

          }

          return this.$vs.notify({
            title: 'Failed to fetch service list',
            text: details,
            color: 'danger',
            position: 'top-right',
          });
        });

    },
    selectFile() {
      const files = this.$refs.file.files;

      if (files.length > 0) {
        this.file = files[0];
      }

    },
    initiateProductUpdate() {

      const payload = {
        name: this.data_local.name,
        status: this.data_local.status,
        description: this.data_local.description,
        partner_id: this.data_local.partner_id,
        service: this.data_local.service.code,
        position: parseInt(this.data_local.position)
      };

      this.$vs.loading();

      this.$http.post(`billing/products/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;
            const pictureId = this.data_local.image || 'default';
            this.data_local.photoURL = `https://portal-cdn.rapid.education/product-pictures/${pictureId}.jpeg`;
            this.loadServices();

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();

          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {data:{message: 'An unknown error has occurred'}}).data.message,
            color: 'danger',
          });
        });
    },
    submitPicture() {

      if (typeof this.file !== 'object') {
        return this.$vs.notify({
          title: 'Error',
          text: 'You must select a file to upload',
          color: 'danger',
        });
      }
      this.$vs.loading();

      const formData = new FormData();
      formData.append('upload', this.file);

      this.$http.post(`billing/products/${this.data.id}/image`, formData)
        .then((response) => {
          this.$vs.loading.close();

          if (response.response) {
            response = response.response;
          }

          if (response.data.data) {

            this.data_local.photoURL = `https://portal-cdn.rapid.education/product-pictures/${response.data.data.image ||
            'default'}.jpeg`;

            this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s' settings have been updated`,
              color: 'success',
            });
          }

        })
        .catch(e => {
          this.$vs.loading.close();
        });

    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style>
.avatar {
  width: 15rem;
  height: 15rem;
}
</style>
