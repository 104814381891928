var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"product-edit-tab-info"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:w-fyll"},[_c('div',{staticClass:"flex items-end"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ProductIcon","svgClasses":"w-5 h-5"}}),_c('span',{staticClass:"leading-none font-medium"},[_vm._v("Add Children to Product")])],1),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-1/2 mt-6"},[_c('label',{staticClass:"text-sm",attrs:{"for":"product"}},[_vm._v("Product Search")]),_c('vue-simple-suggest',{ref:"suggestComponent",attrs:{"controls":{
              selectionUp: [38, 33],
              selectionDown: [40, 34],
              select: [13, 36],
              hideList: [27, 35]
            },"debounce":200,"filter-by-query":false,"list":_vm.getList,"max-suggestions":10,"min-length":3,"nullable-select":true,"prevent-submit":true,"display-attribute":"text","mode":"select","placeholder":"Search Products...","value-attribute":"id"},on:{"select":_vm.onSuggestSelect},scopedSlots:_vm._u([{key:"misc-item-above",fn:function(ref){
            var suggestions = ref.suggestions;
            var query = ref.query;
return [(!_vm.loading && suggestions.length === 0)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("Sorry, no matching options.")])]):_vm._e()]}},{key:"suggestion-item",fn:function(scope){return _c('div',{attrs:{"title":scope.suggestion.description}},[_c('div',{staticClass:"text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.boldenSuggestion(scope))}})])])}},{key:"misc-item-below",fn:function(ref){
            var suggestions = ref.suggestions;
return (_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("Loading...")])]):_vm._e()}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('div',{staticClass:"g"},[_c('input',{attrs:{"id":"product","type":"text"}})])])],1),_c('div',{staticClass:"vx-col vx-row w-1/2 mt-6"},[_c('div',{staticClass:"vx-col sm:w-3/4 w-full"},[_c('label',{staticClass:"text-sm"},[_vm._v("Product Pricing")]),_c('v-select',{attrs:{"disabled":_vm.pricing.length === 0,"options":_vm.pricing,"color":"danger","label":"priceDisplay"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('div',{staticClass:"vx-col sm:w-1/4 w-full"},[_c('label',{staticClass:"text-sm"},[_vm._v("Add Child")]),_c('vs-button',{attrs:{"disabled":_vm.price === null,"color":"danger","icon":"icon-plus","icon-pack":"feather","type":"gradient"},on:{"click":_vm.addChild}})],1)])]),_c('vs-row',[_c('div',{staticClass:"flex items-end pt-10"},[_c('span',{staticClass:"leading-none font-medium"},[_vm._v("Existing Children")])]),_c('vs-table',{staticClass:"flex w-full pt-3",attrs:{"data":_vm.children},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":tr.name}},[_c('router-link',{attrs:{"to":("/a/products/" + (tr.product_id))}},[_vm._v(_vm._s(tr.name))])],1),_c('vs-td',[_vm._v(_vm._s(tr.display))]),_c('vs-td',[_c('vs-button',{attrs:{"color":"danger","icon":"icon-minus","icon-pack":"feather","type":"gradient"},on:{"click":function($event){return _vm.removeChild(tr.id)}}})],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Product")]),_c('vs-th',[_vm._v("Price")]),_c('vs-th',[_vm._v("Actions")])],1)],2)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }